import { BsPlusLg } from "react-icons/bs";
import { Collapse } from '@mantine/core';

export const CustomCollapse = (props) => {
    const {value, isOpen=0, setIsOpen=()=>{}} = props
    const {id, title = "title", content = "content"} = value
    return (
        <div>
            <div 
                onClick={()=>{id == isOpen ? setIsOpen(0) : setIsOpen(id)}}
                className={"collapse_title mt_20 color_primary_hover_fourth "}
            >
                <h2 className={id == isOpen ? 'color_fourth' : 'color_primary'}>
                    {title} 
                </h2>
                <BsPlusLg className={id == isOpen ? 'color_fourth' : 'color_primary'} size={25}/>
            </div>
            <Collapse in={id === isOpen}>
                <div className='collapse_content'>
                    <p className='color_secondary'>
                        {content}
                    </p>
                </div>
            </Collapse>
        </div>
    )
}