import { motion } from 'framer-motion';
import { Image } from '@mantine/core';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { _animationLeftToRight, _animationHoverGetText_Image, _animationHoverGetText_Background, _animationHoverGetText_Text } from '@/libraries/animation';
import serviceContentData from '@/components/data/serviceContent.json';


SwiperCore.use([Navigation, Autoplay]);

export const HoverChange = (props) => {
    const {
        title = "title",
        imagePath = "https://via.placeholder.com/130x130",
        content = "content",
        device
    } = props
    return (
        <div className='hover_change_component'>
            <motion.div
                className='position_relative'
                initial="visible"
                whileHover="hover"
            >
                <div className='hover_change'>
                    <motion.div
                        variants={_animationHoverGetText_Image()}
                    >
                        {device !== 'mobile' ? (
                            <Image
                                width={'130px'}
                                height={'130px'}
                                src={imagePath}
                                withPlaceholder
                            />
                        ) : (
                            <Image
                                width={'55px'}
                                height={'55px'}
                                src={imagePath}
                                withPlaceholder
                            />
                        )}
                    </motion.div>
                </div>

                <motion.div
                    className='hover_change_background_color'
                    variants={_animationHoverGetText_Background()}
                />
                <motion.div
                    className='hover_change_text_hover_out'
                    variants={_animationHoverGetText_Text()}
                >
                    <div className='hover_change_text_hover_out_text color_primary hover_text_1'>
                        {content}
                    </div>
                </motion.div>
            </motion.div>
            {device !== 'mobile' && (
                <div className='hover_change_title'>
                    <h3>{title}</h3>
                </div>
            )}
        </div>
    )
}

export const OurClient = (props) => {
    const { ocTitleSection, ocTitleSectionControls } = props;
    const ocContent = serviceContentData.custom;

    return (
        <section className='container'>
            <div ref={ocTitleSection} className='home_section_oc_title_inner'>
                <motion.h1
                    animate={ocTitleSectionControls}
                    variants={_animationLeftToRight({ duration: 1 })}
                >
                    OUR CLIENTS
                </motion.h1>
            </div>
            <Swiper
                spaceBetween={30}
                slidesPerView={2.5}
                loop={true}
                autoplay={{
                    delay: 0, // Delay between transitions (in ms). If this parameter is not specified, auto play will be disabled
                    disableOnInteraction: false, // Continue autoplay even after user interaction
                }}
                speed={5000} // Speed of transition in milliseconds. Adjust as needed.
                freeMode={true}
                freeModeMomentum={false}
            >
                {ocContent.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Image
                            width={'100%'}
                            src={item.imagePath}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}
