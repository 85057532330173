// External library imports
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState, useRef } from 'react';
import { Image, AspectRatio } from '@mantine/core';
import { motion, useAnimationControls, useInView, animate } from 'framer-motion';
import _, { reduce } from 'lodash';

// Internal imports
import { PageLayout } from '@/components/mobile';
import { _getURL } from "@/api/index";
import { getCookie, setCookie } from 'cookies-next';
import Redux, { updatePath } from "@/action";
import { LoaderFull } from '@/components/loader';
import {
    _animationLeftToRight, _animationRightToLeft, _animationBottomToTop,
    _animationHoverButton_Background, _animationHoverButton_Border, _animationHoverButton_Text,
    _animationHoverGetText_Image, _animationHoverGetText_Background, _animationHoverGetText_Text
} from '@/libraries/animation';
import LandingPage from '@/components/landingPage';
import { CustomCollapse } from '@/components/collapse';
import { HoverChange, OurClient } from '@/components/custom';
import serviceContentData from '@/components/data/serviceContent.json';
import { Standalone as Cover } from '@/components/pagecover';
import { CustomButton } from '@/components/button';

function Counter({ from, to, extraText = "" }) {
    const ref = useRef();

    useEffect(() => {
        const controls = animate(from, to, {
            duration: 3,
            onUpdate(value) {
                ref.current.textContent = value.toFixed(0) + extraText;
            }
        });
        return () => controls.stop();
    }, []);

    return <div ref={ref} className='custom_text_1' />;
}

export default function Index(props) {
    const { store, dispatch } = Redux();
    const { t, device, session } = props;
    const [loading, setLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(0)
    const [firstTimeLandingPage, setFirstTimeLandingPage] = useState(false)
    const [animationCountDown, setAnimationCountDown] = useState(false)

    const enhanceYourWebsiteContent = serviceContentData.enhanceWebsiteContent;
    const osContent = serviceContentData.osContent;

    // animation controller
    // banner
    const bannerSectionControls = useAnimationControls()
    // our service
    const osSection = useRef(null)
    const osSectionInView = useInView(osSection, { margin: "-50px 0px -50px 0px", once: true })
    const osSectionControls = useAnimationControls()
    // enhance your website
    const eywSection = useRef(null)
    const eywSectionInView = useInView(eywSection, { margin: "-10% 0px -10% 0px", once: true })
    const eywSectionControls = useAnimationControls()
    // go digital
    const gdSection = useRef(null)
    const gdSectionInView = useInView(gdSection, { margin: "-10% 0px -10% 0px", once: true })
    const gdSectionControls = useAnimationControls()
    // our client title
    const ocTitleSection = useRef(null)
    const ocTitleSectionInView = useInView(ocTitleSection, { margin: "-10% 0px -10% 0px", once: true })
    const ocTitleSectionControls = useAnimationControls()
    // our client content
    const ocContentSection = useRef(null)
    const ocContentSectionInView = useInView(ocContentSection, { margin: "-30px 0px -30px 0px", once: true })
    const ocContentSectionControls = useAnimationControls()

    useEffect(() => {
        if (!store.path.preloader) {
            setFirstTimeLandingPage(true);
            dispatch(updatePath({ key: "preloader", path: true }));
        }

        if (!firstTimeLandingPage) {
            bannerSectionControls.start('visible');
        }

        if (osSectionInView) {
            osSectionControls.start('visible');
        } else {
            osSectionControls.start('hidden');
        }

        if (eywSectionInView) {
            eywSectionControls.start('visible');
        } else {
            eywSectionControls.start('hidden');
        }

        if (gdSectionInView) {
            gdSectionControls.start('visible');
            if (animationCountDown === false) {
                setAnimationCountDown(true)
            }
        } else {
            gdSectionControls.start('hidden');
        }

        if (ocTitleSectionInView) {
            ocTitleSectionControls.start('visible');
        } else {
            ocTitleSectionControls.start('hidden');
        }

        if (ocContentSectionInView) {
            ocContentSectionControls.start('visible');
        } else {
            ocContentSectionControls.start('hidden');
        }
    }, [firstTimeLandingPage, osSectionInView, eywSectionInView, gdSectionInView, ocTitleSectionInView, ocContentSectionInView])

    // Component structure
    const contentStruct = {
        landingPage: firstTimeLandingPage && <LandingPage setFirstTimeLandingPage={setFirstTimeLandingPage} />,
        cover: <Cover page='home' {...props} />,
        ourServices: (
            <section className='container'>
                <div className='home_section_os_title_inner'>
                    <h1>
                        Our Services
                    </h1>
                </div>
                <div ref={osSection} className='home_section_os_list'>
                    {
                        reduce(osContent, (result, value, key) => {
                            result.push(
                                <motion.div
                                    key={key}
                                    animate={osSectionControls}
                                    variants={_animationBottomToTop({ duration: 0.5 + (0.1 * key) })}
                                >
                                    {device !== 'mobile' ? (
                                        <HoverChange
                                            device={device}
                                            title={value.title}
                                            imagePath={value.imagePath}
                                            content={value.content}
                                        />
                                    ) : (
                                        <div className='home_os_mb'>
                                            <HoverChange
                                                device={device}
                                                imagePath={value.imagePath}
                                            />
                                            <div className='home_os_mb_content'>
                                                <h2>{value.title}</h2>
                                                <p>{value.content}</p>
                                            </div>
                                        </div>
                                    )}
                                </motion.div>
                            )
                            return result;
                        }, [])
                    }
                </div>
                <motion.div
                        className= "mt_10"
                        initial={"hidden"}
                        animate={bannerSectionControls}
                        variants={_animationRightToLeft({ duration: 1 })}
                    >
                        {device !== 'mobile' ? (
                            <CustomButton borderColorStart={"var(--secondaryColor)"} borderColorEnd={"var(--secondaryColor)"} text={"Explore more"} href="/service" />
                        ) : (
                            <CustomButton borderColorStart={"var(--fourthColor)"} borderColorEnd={"var(--fourthColor)"} text={"Explore more"} href="/service" />
                        )}
                    </motion.div>
            </section>
        ),
        enhanceYourWebsite: (
            <section className='home_section_eyw_overflow'>
                <div ref={eywSection} className='container mt_100'>
                    <motion.div
                        className='position_relative'
                        animate={eywSectionControls}
                        variants={_animationBottomToTop({ duration: 1 })}
                    >
                        <div className='home_section_eyw'>
                            <div className='home_section_eyw_left'>
                                <h1 className='color_primary'>
                                    Why Choose Us
                                </h1>
                                <h2 className='color_primary mt_30'>
                                    At Corebase Technologies, we don't just provide services; we provide solutions that empower your business. Whether you're a startup looking to make your mark or an established enterprise aiming to innovate, we have the expertise to support your journey.
                                </h2>
                            </div>
                            <div className='home_section_eyw_right'>
                                {
                                    reduce(enhanceYourWebsiteContent, (result, value, key) => {
                                        result.push(
                                            <CustomCollapse key={key} value={value} isOpen={isOpen} setIsOpen={setIsOpen} />
                                        )
                                        return result;
                                    }, [])
                                }
                            </div>
                            <div className='home_section_eyw_line_1'>
                                <img src='/assets/images/line_yellow_home_1.png' />
                            </div>
                        </div>
                        <div className='home_section_eyw_line_2'>
                            <img src='/assets/images/line_yellow_home_2.png' />
                        </div>
                    </motion.div>
                </div>
            </section>
        ),
        goDigital: (
            <section className='home_section_gd_overflow'>
                <div ref={gdSection} className='container'>
                    <motion.div
                        className='position_relative'
                        animate={gdSectionControls}
                        variants={_animationBottomToTop({ duration: 1 })}
                    >
                        <div className='home_section_gd'>
                            <h1>
                                Our Tech Journey
                            </h1>
                            <h2>
                                Our extensive portfolio reflects our commitment to excellence and innovation in every project we undertake. Explore our journey and discover how we can bring our expertise to your next venture.
                            </h2>
                            <div className='home_section_gd_list'>
                                <div className='home_section_gd_item'>
                                    <div className='custom_text_1'>
                                        {animationCountDown === true ? <Counter from={0} to={150} extraText={"+"} /> : "0+"}
                                    </div>
                                    <div className='custom_text_2'>
                                        Project Delivered
                                    </div>
                                </div>
                                <div className='home_section_gd_item'>
                                    <div className='custom_text_1'>
                                        {animationCountDown === true ? <Counter from={0} to={10} extraText={"+"} /> : "0"}
                                    </div>
                                    <div className='custom_text_2'>
                                        Years of experience
                                    </div>
                                </div>
                                <div className='home_section_gd_item'>
                                    <div className='custom_text_1'>
                                        {animationCountDown === true ? <Counter from={0} to={20} extraText={"+"} /> : "0"}
                                    </div>
                                    <div className='custom_text_2'>
                                        Expert in the house
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='home_section_gd_line'>
                            <img src='/assets/images/line_blue_home.png' />
                        </div>
                    </motion.div>
                </div>
            </section>
        ),
        ourClients: (
            <div>
                <OurClient ocTitleSection={ocTitleSection} ocTitleSectionControls={ocTitleSectionControls} ocContentSection={ocContentSection} ocContentSectionControls={ocContentSectionControls} />
            </div>
        )
    };

    return (
        <>
            {loading ? <LoaderFull /> : (
                device === 'mobile' ? (
                    <PageLayout title="home header" {...props}>
                        {contentStruct.landingPage}
                        {contentStruct.cover}
                        {contentStruct.ourServices}
                        {contentStruct.enhanceYourWebsite}
                        {contentStruct.goDigital}
                        {contentStruct.ourClients}
                    </PageLayout>
                ) : (
                    <>
                        {contentStruct.landingPage}
                        {contentStruct.cover}
                        {contentStruct.ourServices}
                        {contentStruct.enhanceYourWebsite}
                        {contentStruct.goDigital}
                        {contentStruct.ourClients}
                    </>
                )
            )}
        </>
    );
}

export async function getStaticProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
    };
}
