import React, { useState } from 'react';
import { motion } from 'framer-motion';

const animationLandingPage_1 = {
    initial: { 
        opacity: 1 ,
        x: 0,
    },
    animate: { 
        opacity: 0,
        x: "-500px",
        transition: {
            type: 'tween',
            duration: 1.5,
            bounce: 0,
            delay: 1
        }
    },
}

const animationLandingPage_2 = {
    initial: { 
        scale: 1,
        // opacity: 1,
    },
    animate: { 
        scale: 20,
        // opacity: 0,
        transition: {
            type: 'tween',
            duration: 2,
            bounce: 0,
            delay: 1.5
        }
    },
}

const animationLandingPage_3 = {
    initial: { 
        opacity: 1,
        display: 'flex'
    },
    animate: { 
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 1,
            delay: 2,
        },
        transitionEnd: { display: "none" }
    },
}

const Index = (props) => {
    const {setFirstTimeLandingPage = () =>{}} = props
    return (
        <motion.div 
            className='landing_page_position'
            initial="initial"
            animate="animate"
            variants={animationLandingPage_3}
            onAnimationComplete={()=>{setFirstTimeLandingPage(false)}}
        >
            <div className='landing_page'>
                <motion.div 
                    className='landing_page_left'
                    variants={animationLandingPage_2}
                >
                    <img 
                        className='landing_page_image'
                        src={"/assets/images/logo_left.png"} 
                    />
                </motion.div>
                <div className='landing_page_center'/>
                <motion.div 
                    className='landing_page_right'
                    variants={animationLandingPage_1}
                >
                    <img 
                        className='landing_page_image'
                        src={"/assets/images/logo_right.png"} 
                    />
                </motion.div>
            </div>
        </motion.div>
    )
}

export default Index;